<template>
    <div
        :class="{
            'workflow-builder-pass pointer-action': true,
            'workflow-builder-pass--open': showModal,
        }"
        @click="openModal"
    >
        <span> {{ displayDate }}</span>
        <i class="fa fa-calendar" v-b-tooltip.hover="`Configure dates`" />
        <PassModal v-model="passValue" :show.sync="showModal" @input="emitInput" />
    </div>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'
import Feedback from '@/components/Feedback.vue'
import ScheduleBackfill from '@/components/ScheduleBackfill.vue'

export default {
    components: {
        WitModal,
        Feedback,
        ScheduleBackfill,
        PassModal: () => import('./PassModal.vue'),
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            passValue: {},
            showModal: false,
        }
    },
    computed: {
        displayDate() {
            if (this.passValue && this.passValue.length) {
                const [{key, value}] = this.passValue

                if (key === 'lastXDays') {
                    return `Last ${value} day${value !== 1 ? 's' : ''}`
                }
                if (key === 'staticDateRange') {
                    const format = new Intl.DateTimeFormat()
                    return `${format.format(new Date(value.start))} - ${format.format(new Date(value.end))}`
                }
                if (key === 'dynamicDateRange') {
                    return 'Dynamic Date Range'
                }

                if (key === 'specifiedDateRange') {
                    return 'Specified Date Range'
                }
            }

            return 'Today'
        },
    },
    created() {
        this.passValue = this.value
    },
    methods: {
        openModal() {
            this.showModal = true
        },

        emitInput() {
            this.$emit('input', this.passValue)
        },
    },
}
</script>

<style lang="scss">
.workflow-builder-pass {
    $self: &;

    height: 36px;
    line-height: 36px;
    margin-left: 10px;
    font-weight: 500;

    > span {
        opacity: 0.4;
    }

    i {
        font-size: 16px;
    }

    &--invalid:not(#{ $self }--open) {
        color: red;
    }
}

.workflow-builder-pass__modal {
    .radio-form-label {
        margin-bottom: 0;

        legend {
            font-weight: 600;
        }

        .custom-control-inline {
            margin-bottom: 5px;
        }
    }

    .radio-form-radio-group {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .radio-form-row {
        margin-right: 0;
        margin-left: 0;
        line-height: calc(1.5em + 0.75rem + 2px);
        padding-bottom: 1rem;
    }
}
</style>
