<template>
    <div :class="{'workflow-builder-task__wrapper': true, 'workflow-builder-task__wrapper--invalid': invalid}">
        <div class="workflow-builder-task">
            <div class="workflow-builder-task__left">
                <wit-select
                    v-model="multiselectResource"
                    label="name"
                    placeholder="Select Resource"
                    :options="resourceOptions"
                    @input="selectResource"
                    :allow-empty="false"
                    class="workflow-builder-task__select"
                >
                    <template #selected-option="option">
                        <div class="workflow-builder-task__select-option">
                            <img :src="option.img" v-if="option.img" />
                            <span>{{ option.name }}</span>
                        </div>
                    </template>
                    <template #option="option">
                        <div class="workflow-builder-task__select-option">
                            <img :src="option.img" v-if="option.img" />
                            <span>{{ option.name }}</span>
                        </div>
                    </template>
                </wit-select>
            </div>
            <div class="workflow-builder-task__right">
                <Pass v-model="localState.passValue" v-if="!inMap" @input="emitInput" />
                <TaskSettings v-model="localState.retryValue" @input="emitInput" />
                <i
                    class="workflow-builder-task__remove icon-trash pointer-action"
                    @click="remove"
                    v-b-tooltip.hover="`Remove`"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Pass from './Pass.vue'
import TaskSettings from './TaskSettings.vue'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Pass,
        TaskSettings,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object,
            required: true,
        },
        types: {
            type: Object,
            default: () => {},
        },
        inMap: {
            type: Boolean,
            default: false,
        },
        stepChecked: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const id = this.$randomId()
        return {
            id,
            localState: {
                name: id,
                comment: '',
                resource: {
                    type: '',
                    id: '',
                },
                type: 'Task',
                passValue: [],
                retryValue: {},
            },
            multiselectResource: null,
            passValueValid: true,
        }
    },
    validations: {
        localState: {
            resource: {
                type: {required},
                id: {required},
            },
        },
    },
    computed: {
        ...mapGetters({
            collect: 'collect/all',
            process: 'process/all',
            report: 'report/all',
        }),
        resourceOptions() {
            return [
                ...this.collect.map((el) => {
                    const type = this.types[`${el.type} ${el.subtype}`]
                    return {resource: 'collect', img: type ? type.img : null, resourceType: 'Collect', ...el}
                }),
                ...this.process.map((el) => {
                    const type = this.types[`${el.type} ${el.subtype}`]
                    return {resource: 'process', img: type ? type.img : null, resourceType: 'Process', ...el}
                }),
                ...this.report.map((el) => ({
                    resource: 'report',
                    img: require('@/assets/logotypes/datastudio_36.svg'),
                    resourceType: 'Report',
                    ...el,
                })),
            ]
        },
        icon() {
            if (this.value && this.value.resource && this.value.resource.type) {
                if (['collect', 'process'].includes(this.value.resource.type)) {
                    const resource = this[`${this.value.resource.type}`].find((el) => el.id === this.value.resource.id)

                    if (resource) {
                        const type = this.types[`${resource.type} ${resource.subtype}`]
                        return type ? type.img : null
                    }
                }
            }
        },
        invalid() {
            return this.stepChecked ? this.$v.$invalid : null
        },
        valueComp() {
            const {
                name,
                comment,
                resource,
                type,
                passValue,
                retryValue: {retryAttempts, retryDelay},
            } = this.localState

            return {name, comment, resource, type, passValue, retryAttempts, retryDelay}
        },
    },
    created() {
        const {name, comment, resource, type, passValue, retryAttempts, retryDelay} = this.value
        this.localState = cloneDeep({...this.localState, name, comment, resource, type, passValue})

        if (this.localState.resource && this.localState.resource.id) {
            this.multiselectResource = this.resourceOptions.find((el) => el.id === this.value.resource.id)
        }

        if (retryAttempts || retryDelay) {
            this.localState.retryValue = {retryAttempts, retryDelay}
        }

        this.emitInput()
    },
    methods: {
        remove() {
            this.$emit('remove')
        },

        selectResource(resource) {
            this.localState.resource = {
                id: resource.id,
                type: resource.resource,
            }
            this.localState.name = `${resource.resource} - ${resource.name} - ${this.id}`

            this.emitInput()
        },

        emitInput() {
            this.$emit('input', this.valueComp)
            this.emitUpdate()
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },
    },
}
</script>

<style lang="scss">
.workflow-builder-task__wrapper {
    $self: &;

    &.sortable-chosen {
        .workflow-builder-task {
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
            cursor: grabbing;
        }

        .vs__dropdown-menu {
            display: none;
            visibility: hidden;
        }

        .vs--open {
            .vs__selected-options {
                .vs__selected {
                    padding-top: 12px;
                    opacity: 1;
                    position: initial;
                }

                input {
                    opacity: 0;
                }
            }
        }
    }

    &--invalid#{ $self } {
        .workflow-builder-task {
            box-shadow: rgba(255, 0, 0, 0.3) 0px 1px 6px, rgba(255, 0, 0, 0.3) 0px 1px 4px;
        }
    }

    .workflow-builder-task {
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
        background-color: white;
        padding: 5px 15px;
        margin: 15px 0;

        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        &__right {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        &__left {
            flex-grow: 1;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            input {
                border: 0;
                padding: 0 100px 0 0;

                &:focus {
                    outline: none;
                }
            }

            .wit-select {
                flex-grow: 1;
                min-width: calc(min(80%, 300px));
                max-width: calc(min(80%, 300px));
                height: 48px;

                input {
                    height: 48px;

                    &::placeholder {
                        padding-left: 24px;
                    }
                }

                .vs__selected {
                    padding: 0 10px 0 0;
                }

                .vs__dropdown-toggle {
                    border: 0;
                }

                .vs__actions {
                    padding-bottom: 5px;
                }

                ul {
                    li {
                        padding: 8px 10px;
                        margin-right: 10px;
                    }

                    img {
                        height: 24px;
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }

        i {
            height: 36px;
            line-height: 36px;
            margin-left: 10px;
            font-size: 16px;
        }
    }
}

.workflow-builder-task__select-option {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    img {
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }
}
</style>
