<template>
    <div
        :class="{
            'workflow-builder-task-settings pointer-action': true,
        }"
        @click="openModal"
        v-b-tooltip.hover="`Advanced Settings`"
    >
        <i class="icon-settings" />
        <TaskSettingsModal v-model="retryValue" :show.sync="showModal" @input="emitInput" />
    </div>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'
import Feedback from '@/components/Feedback.vue'

export default {
    components: {
        WitModal,
        Feedback,
        TaskSettingsModal: () => import('./TaskSettingsModal.vue'),
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            retryValue: {},
            showModal: false,
        }
    },
    created() {
        this.retryValue = this.value
    },
    methods: {
        openModal() {
            this.showModal = true
        },

        emitInput() {
            this.$emit('input', this.retryValue)
        },
    },
}
</script>

<style lang="scss">
.workflow-builder-task-settings {
    height: 36px;
    line-height: 36px;
    font-weight: 500;

    i {
        font-size: 16px;
    }
}

.workflow-builder-task-settings__modal {
    legend {
        font-weight: 600;
    }

    .vue-slider-col {
        padding-top: 8px;
        z-index: 0;
    }
}
</style>
