<template>
    <div>
        <b-form-group label-cols-lg="3" label="Start Date">
            <Datetime
                @input="onChange"
                format="yyyy/M/d"
                placeholder="Start Date"
                input-class="form-control"
                v-model="$v.backfill.start.$model"
            ></Datetime>
        </b-form-group>
        <b-form-group label-cols-lg="3" label="End Date">
            <Datetime
                @input="onChange"
                format="yyyy/M/d"
                placeholder="End Date"
                input-class="form-control"
                v-model="$v.backfill.end.$model"
            ></Datetime>
        </b-form-group>
    </div>
</template>

<script>
import Vue from 'vue'
import {Datetime} from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import {Settings} from 'luxon'

Settings.defaultLocale = 'en'

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import {required, between} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback'

const isEqual = require('fast-deep-equal')

export default {
    components: {
        Datetime,
        Feedback,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            backfill: {
                start: '',
                end: '',
            },
        }
    },
    watch: {
        value() {
            this.backfill = {...this.value}
        },
    },
    validations: {
        backfill: {
            start: {
                required,
            },
            end: {
                required,
            },
        },
    },
    created() {
        this.backfill = {...this.value}
    },
    methods: {
        onChange() {
            if (!isEqual(this.backfill, this.value)) {
                this.$emit('input', {...this.backfill})
            }
        },
    },
}
</script>

<style lang=""></style>
